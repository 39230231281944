<template>
	<v-sheet class="vendor" id="vendor" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					v-if="stepper > 1"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save <template v-if="stepper < 3">&amp; Next</template>
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<v-stepper flat tile class="item-group-stepper" v-model="stepper">
					<v-stepper-header>
						<v-stepper-step class="py-2 btx-label" :complete="stepper > 1" step="1">
							Overview
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step class="py-2 btx-label" :complete="stepper > 2" step="2">
							Contact Persons
						</v-stepper-step>

						<v-divider></v-divider>

						<v-stepper-step class="py-2 btx-label" :complete="stepper > 3" step="3">
							Address
						</v-stepper-step>
					</v-stepper-header>

					<v-stepper-items>
						<v-stepper-content class="pt-0" step="1">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 185px); position: relative"
							>
								<v-form
									ref="overviewForm"
									v-model.trim="formValid"
									lazy-validation
									v-on:submit.stop.prevent="updateOrCreate()"
								>
									<v-row>
										<v-col md="8">
											<v-row>
												<template v-if="false">
													<v-col md="3" class="my-auto py-0">
														<label for="first-name" class="btx-label mt-2 required">Primary Contact</label>
													</v-col>
													<v-col md="9" class="py-0">
														<v-layout>
															<v-flex class="max-width-100px">
																<SelectInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	:items.sync="listTitle"
																	id="salutation"
																	placeholder="Title"
																	v-model="vendor.salutation"
																	v-on:change="updateDefaultPerson()"
																></SelectInput>
															</v-flex>
															<v-flex class="mx-2">
																<TextInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="first-name"
																	placeholder="First Name"
																	:rules="[vrules.required(vendor.first_name, 'First Name')]"
																	:class="{
																		required: !vendor.first_name,
																	}"
																	v-model="vendor.first_name"
																	v-on:keyup="updateVendorDisplayName()"
																></TextInput>
															</v-flex>
															<v-flex>
																<TextInput
																	hide-details
																	:disabled="pageLoading"
																	:loading="pageLoading"
																	id="last-name"
																	placeholder="Last Name"
																	v-model="vendor.last_name"
																	v-on:keyup="updateVendorDisplayName()"
																></TextInput>
															</v-flex>
														</v-layout>
													</v-col>
												</template>
												<v-col md="3" class="my-auto py-0">
													<label for="company-name" class="btx-label mt-2 required">Company Name</label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="company-name"
														placeholder="Company Name"
														:rules="[vrules.required(vendor.company_name, 'Company Name')]"
														:class="{
															required: !vendor.company_name,
														}"
														v-on:keyup="updateVendorDisplayName()"
														v-model="vendor.company_name"
													></TextInput>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="display-name" class="btx-label mt-2 required"
														>Display Name
														<TooltipQuestion>
															<template v-slot:text
																>This name will be displayed on<br />the transactions you create for<br />this
																supplier</template
															>
														</TooltipQuestion>
													</label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														:rules="[vrules.required(vendor.display_name, 'Display Name')]"
														:class="{
															required: !vendor.display_name,
														}"
														placeholder="Display Name"
														v-model="vendor.display_name"
														v-on:keyup="updateDefaultPerson()"
													></TextInput>
												</v-col>
												<v-col md="3" class="my-auto py-0">
													<label for="display-name" class="btx-label mt-2">UEN </label>
												</v-col>
												<v-col md="9" class="py-0">
													<TextInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="display-name"
														placeholder="UEN Number"
														v-model="vendor.uen"
														v-on:keyup="updateDefaultPerson()"
													></TextInput>
												</v-col>
												<template v-if="!uuid">
													<v-col md="3" class="my-auto py-0">
														<label for="vendor-email" class="btx-label mt-2 required">Email Address</label>
													</v-col>
													<v-col md="9" class="py-0">
														<EmailInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor-email"
															placeholder="Email Address"
															v-model="vendor.email"
															:rules="[vrules.required(vendor.email, 'Email Address')]"
															:class="{
																required: !vendor.email,
															}"
															v-on:keyup="updateDefaultPerson()"
														></EmailInput>
													</v-col>
												</template>
												<v-col md="3" class="my-auto py-0">
													<label for="vendor-phone-no" class="btx-label mt-2 required">Phone</label>
												</v-col>
												<v-col md="9" class="py-0">
													<PhoneTemplate
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="vendor-phone-no"
														placeholder="Phone No."
														v-model="vendor.phone_no"
														:rules="[vrules.required(vendor.phone_no, 'Phone No.')]"
														:class="{
															required: !vendor.phone_no,
														}"
														v-on:keyup="updateDefaultPerson()"
													></PhoneTemplate>
												</v-col>
												<template v-if="false">
													<v-col md="3" class="my-auto py-0">
														<label for="vendor-fax" class="btx-label mt-2">Fax</label>
													</v-col>
													<v-col md="9" class="py-0">
														<PhoneTemplate
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor-fax"
															placeholder="Fax"
															v-model="vendor.fax"
														></PhoneTemplate>
													</v-col>
													<v-col md="3" class="my-auto py-0">
														<label for="vendor-website" class="btx-label mt-2">Website</label>
													</v-col>
													<v-col md="9" class="py-0">
														<TextInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															id="vendor-website"
															placeholder="Website"
															v-model="vendor.website"
														></TextInput>
													</v-col>
													<v-col md="3" class="py-0">
														<label for="vendor-remark" class="btx-label mt-2"
															>Remarks<br /><span class="text-muted">(For Internal Use)</span></label
														>
													</v-col>
													<v-col md="9" class="py-0">
														<TextAreaInput
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															:rows="5"
															id="vendor-remark"
															placeholder="Remarks (For Internal Use)"
															v-model="vendor.remark"
														></TextAreaInput>
													</v-col>
												</template>
											</v-row>
										</v-col>
										<v-col md="4">
											<v-row>
												<v-col md="12" class="my-6 py-0 text-center">
													<Avatar can-change v-model="vendor.image"></Avatar>
												</v-col>
											</v-row>
										</v-col>
									</v-row>
								</v-form>
							</perfect-scrollbar>
						</v-stepper-content>
						<v-stepper-content class="pt-0" step="2">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 185px); position: relative"
							>
								<v-form
									ref="personForm"
									v-model.trim="formValid"
									lazy-validation
									v-on:submit.stop.prevent="updateOrCreate()"
								>
									<ContactPerson
										:detail="vendor"
										v-on:saveContactPerson="updateContactPerson"
										:update-contact="updateSupplier"
										:default-contact-persons="contact_person"
									>
									</ContactPerson>
								</v-form>
							</perfect-scrollbar>
						</v-stepper-content>
						<v-stepper-content class="pt-0" step="3">
							<perfect-scrollbar
								:options="{ suppressScrollX: true }"
								class="scroll custom-box-top-inner-shadow"
								style="max-height: calc(100vh - 185px); position: relative"
							>
								<v-form
									ref="addressForm"
									v-model.trim="formValid"
									lazy-validation
									v-on:submit.stop.prevent="updateOrCreate()"
								>
									<v-row>
										<v-col md="6">
											<label class="btx-label mt-2">BILLING ADDRESS</label>
											<hr />
										</v-col>
										<v-col md="6" v-if="false">
											<label class="btx-label mt-2">SHIPPING ADDRESS</label>
											<hr />
										</v-col>
									</v-row>
									<v-row v-if="false">
										<v-col md="6"></v-col>
										<v-col md="6">
											<v-btn
												v-on:click="copyBilling"
												text
												depressed
												tile
												color="blue darken-4"
												:disabled="pageLoading"
											>
												<v-icon left>mdi-arrow-down-bold</v-icon>
												Copy Billing Address
											</v-btn>
										</v-col>
									</v-row>
									<v-row>
										<v-col md="6">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: 60vh; position: relative"
											>
												<AddressTemplate
													:disabled="pageLoading"
													:loading="pageLoading"
													v-model="billingAddress"
													:contact-person-list="[]"
												></AddressTemplate>
											</perfect-scrollbar>
										</v-col>
										<v-col md="6" v-if="false">
											<perfect-scrollbar
												:options="{ suppressScrollX: true }"
												class="scroll custom-box-top-inner-shadow"
												style="max-height: 60vh; position: relative"
											>
												<AddressTemplate
													:disabled="pageLoading"
													:loading="pageLoading"
													v-model="shippingAddress"
													:contact-person-list="[]"
												></AddressTemplate>
											</perfect-scrollbar>
										</v-col>
									</v-row>
								</v-form>
							</perfect-scrollbar>
						</v-stepper-content>
					</v-stepper-items>
				</v-stepper>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { findIndex, toSafeInteger } from "lodash";
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { CreateOrUpdateVendor, GetVendor } from "@/core/lib/contact.lib";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import AddressTemplate from "@/view/components/Address";
import PhoneTemplate from "@/view/components/Phone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TooltipQuestion from "@/view/components/TooltipQuestion";
import Avatar from "@/view/components/Avatar";
import ApiService from "@/core/services/api.service";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import ContactPerson from "@/view/components/ContactPerson";
import ObjectPath from "object-path";

export default {
	name: "vendor-create",
	title: "Create Vendor",
	data() {
		return {
			uuid: null,
			cloneUUID: null,
			barcode: null,
			formValid: true,
			designationLists: [],
			updateSupplier: false,
			pageLoading: false,
			billingAddress: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			manageDesignationDialog: false,
			stepper: 1,
			vendor: {
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				uen: null,
				company_name: null,
				image: null,
				designation: null,
				display_name: null,
				email: null,
				phone_no: null,
				fax: null,
				website: null,
				remark: null,
			},
			shippingAddress: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				has_lat_long: 0,
			},
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: null,
					last_name: null,
					display_name: null,
					email: null,
					mobile: null,
				},
			],
		};
	},
	components: {
		Avatar,
		TextInput,
		TextAreaInput,
		TooltipQuestion,
		AddressTemplate,
		ContactPerson,
		PhoneTemplate,
		EmailInput,
		SelectInput,
	},

	methods: {
		getDesignations() {
			this.pageLoading = true;
			ApiService.setHeader();
			ApiService.get(`contact-options`)
				.then(({ data }) => {
					if (data.designation) {
						this.designationLists = data.designation;
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		copyBilling() {
			this.shippingAddress = {
				id: null,
				uuid: null,
				name: this.billingAddress.name ? this.billingAddress.name : null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: this.billingAddress.address_line_1 ? this.billingAddress.address_line_1 : null,
				address_line_2: this.billingAddress.address_line_2 ? this.billingAddress.address_line_2 : null,
				address_city: null,
				address_state: null,
				address_country: this.billingAddress.address_country
					? this.billingAddress.address_country
					: null,
				address_postal_code: this.billingAddress.address_postal_code
					? this.billingAddress.address_postal_code
					: null,
				description: null,
				has_lat_long: 0,
			};
		},
		validateRadio(index) {
			let contactPersons = this.contact_person;
			this.contact_person = contactPersons.map((row) => {
				row.primary = false;
				return row;
			});
			this.contact_person[index].primary = true;
		},
		updateVendorDisplayName() {
			const vendor = this.vendor;
			if (vendor) {
				const display_name = [];
				if (vendor.company_name) {
					display_name.push(vendor.company_name);
				} else {
					if (vendor.first_name) {
						display_name.push(vendor.first_name);
					}
					if (vendor.last_name) {
						display_name.push(vendor.last_name);
					}
				}
				this.vendor.display_name = display_name.join(" ");
				this.updateDefaultPerson();
			}
		},
		updatePersonDisplayName(index) {
			const contact_person = this.contact_person[index];
			if (contact_person) {
				const display_name = [];
				if (contact_person.first_name) {
					display_name.push(contact_person.first_name);
				}
				if (contact_person.last_name) {
					display_name.push(contact_person.last_name);
				}
				this.contact_person[index].display_name = display_name.join(" ");
			}
		},
		updateDefaultPerson() {
			const { salutation, first_name, display_name, last_name, email, phone_no } = this.vendor;
			//if (email) {
			let defaultIndex = toSafeInteger(findIndex(this.contact_person, { email }));

			if (defaultIndex < 0) {
				defaultIndex = 0;
			}

			const person = this.contact_person[defaultIndex];

			if (!person) {
				return false;
			}

			this.contact_person.splice(defaultIndex, 1, {
				id: person.id,
				uuid: person.uuid,
				salutation,
				first_name,
				last_name,
				email,
				display_name,
				mobile: phone_no,
			});
			//}
		},
		removePerson(index) {
			this.contact_person.splice(index, 1);
			if (this.contact_person.length <= 0) {
				this.addPerson();
			}
		},
		addPerson() {
			this.contact_person.push({
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				email: null,
				primary: false,
				full_name: null,
				default: false,
				work_phone: null,
				mobile: null,
			});
		},
		async updateOrCreate() {
			const _this = this;

			if (_this.stepper == 1) {
				const formErrors = _this.validateForm(_this.$refs.overviewForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.overviewForm.validate()) {
					return false;
				}
			}

			if (_this.stepper == 2) {
				if (_this.contact_person.length == 0) {
					_this.$store.commit(SET_ERROR, [
						{ model: true, message: "Please Add Atleast One Contact Person." },
					]);
					return false;
				}

				const formErrors = _this.validateForm(_this.$refs.personForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.personForm.validate()) {
					return false;
				}
			}

			if (_this.stepper == 3) {
				const formErrors = _this.validateForm(_this.$refs.addressForm);
				if (formErrors.length) {
					_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
					return false;
				}

				if (!_this.$refs.addressForm.validate()) {
					return false;
				}
			}

			if (_this.stepper < 3) {
				_this.stepper++;
				return false;
			}

			try {
				_this.pageLoading = true;
				_this.billingAddress.address_type = 1;
				// this.shippingAddress.address_type = 2;
				const formData = {
					contact: _this.vendor,
					address: [_this.billingAddress],
					persons: _this.contact_person,
				};

				const { uuid } = await CreateOrUpdateVendor(formData);

				_this.$router.replace({
					name: "vendor-detail",
					params: { uuid },
					query: { t: new Date().getTime() },
				});

				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Supplier has been saved." },
				]);
				_this.$store.dispatch(SET_LOCAL_DB);
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		initVendor({ contact, contact_person }) {
			const _this = this;

			_this.barcode = contact.barcode;

			_this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Contacts", disabled: true },
				{ text: "Supplier", disabled: true },
				{ text: "Update", disabled: true },
				{ text: _this.barcode, disabled: true },
			]);

			_this.vendor = {
				id: contact.id,
				uuid: contact.uuid,
				salutation: contact.title,
				first_name: contact.first_name,
				last_name: contact.last_name,
				company_name: contact.company_name,
				image: contact.image,
				uen: contact.uen,
				display_name: contact.display_name,
				email: contact.email,
				phone_no: contact.phone,
				fax: contact.fax,
				website: contact.website,
				remark: contact.remarks,
			};

			/* _this.address = {
				id: address[0].id,
				uuid: address[0].uuid,
				name: address[0].name,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: address[0].address_line_1,
				address_line_2: address[0].address_line_2,
				address_city: address[0].address_city,
				address_state: address[0].address_state,
				address_country: address[0].address_country,
				address_postal_code: address[0].address_postal_code,
				description: null,
				has_lat_long: 0,
			}; */

			_this.shippingAddress = contact.shipping_address;
			_this.billingAddress = contact.billing_address;

			_this.contact_person = [];

			for (let i = 0; i < contact_person.length; i++) {
				_this.contact_person.push({
					id: contact_person[i].id,
					uuid: contact_person[i].uuid,
					salutation: contact_person[i].title,
					first_name: contact_person[i].first_name,
					last_name: contact_person[i].last_name,
					display_name: contact_person[i].display_name,
					email: contact_person[i].email,
					mobile: contact_person[i].phone,
					designation: contact_person[i].designation,
				});
			}

			console.log({ contact_person: _this.contact_person });

			if (!_this.contact_person.length) {
				_this.contact_person = [
					{
						id: null,
						uuid: null,
						salutation: "mr",
						first_name: null,
						last_name: null,
						display_name: null,
						email: null,
						mobile: null,
					},
				];
			}
		},
		getVendor() {
			const _this = this;
			GetVendor(_this.uuid)
				.then((data) => {
					_this.initVendor(data);
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCloneVendor() {
			const _this = this;
			GetVendor(_this.cloneUUID)
				.then((data) => {
					console.log({ vendor: data });
					if (ObjectPath.has(data, "contact.id")) {
						ObjectPath.set(data, "contact.id", null);
						ObjectPath.set(data, "contact.uuid", null);
						if (ObjectPath.has(data, "contact.billing_address.id")) {
							ObjectPath.set(data, "contact.billing_address.id", null);
							ObjectPath.set(data, "contact.billing_address.uuid", null);
							ObjectPath.set(data, "contact.billing_address.contact", null);
						}
						if (ObjectPath.has(data, "contact.shipping_address.id")) {
							ObjectPath.set(data, "contact.shipping_address.id", null);
							ObjectPath.set(data, "contact.shipping_address.uuid", null);
							ObjectPath.set(data, "contact.shipping_address.contact", null);
						}
					}

					const contact_person = ObjectPath.get(data, "contact_person", []);
					for (let i = 0; i < contact_person.length; i++) {
						if (ObjectPath.has(data, `contact_person.${i}.id`)) {
							ObjectPath.set(data, `contact_person.${i}.id`, null);
							ObjectPath.set(data, `contact_person.${i}.uuid`, null);
							ObjectPath.set(data, `contact_person.${i}.contact`, null);
						}
					}

					_this.$nextTick(() => {
						_this.initVendor(data);
						_this.vendor.designation = ObjectPath.get(data, "contact_person.0.designation", null);
					});
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.vendor.display_name + " - " + this.barcode;
			}
			return "Create new Supplier";
		},
		updateContactPerson(data) {
			this.contact_person = data;
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Contacts", disabled: true },
			{ text: "Supplier", disabled: true },
			{ text: "Update", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "vendor-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getVendor();
		}

		if (this.uuid) {
			this.updateSupplier = true;
		}

		this.getDesignations();

		this.cloneUUID = ObjectPath.get(this.$route, "query.clone");

		if (this.cloneUUID) {
			this.getCloneVendor();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
